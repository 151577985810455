import { gql } from '@apollo/client/core';

export const ORDER_AGGREGATION_BY_SUPPLIER_QUERY = gql`
  query orderAggregationBySupplier(
    $businessId: ID!
    $supplierId: ID
    $fromDate: ISODate
    $toDate: ISODate
    $monthlyAverage: Boolean
  ) {
    orderAggregation {
      groupBySupplier(
        businessId: $businessId
        supplierId: $supplierId
        fromDate: $fromDate
        toDate: $toDate
        monthlyAverage: $monthlyAverage
      ) {
        nodes {
          supplierId
          amount
          productCount
          orderCount
          firstOrder {
            date
          }
          lastOrder {
            date
          }
          productWithoutAgreementCount
        }
      }
    }
  }
`;

export const GROUP_ORDER_ITEMS_BY_PRODUCT_QUERY_SUPPLIERS_AND_PRODUCTS = gql`
  query orderItemAggregationByProduct(
    $businessId: ID!
    $supplierId: ID
    $fromDate: ISODate
    $toDate: ISODate
    $isPartOfAgreement: Boolean
    $pricePoints: Boolean
  ) {
    orderAggregation {
      groupOrderItemsByProduct(
        businessId: $businessId
        supplierId: $supplierId
        fromDate: $fromDate
        toDate: $toDate
        isPartOfAgreement: $isPartOfAgreement
        pricePoints: $pricePoints
      ) {
        nodes {
          product {
            id
            name
            sku
          }
          supplier {
            id
            name
          }
          amount
          quantity
          orderCount
          pricePoints {
            date
            price
          }
          firstOrder {
            id
            date
          }
          lastOrder {
            id
            date
          }
        }
      }
    }
  }
`;

export const TENANT_TOP_PURCHASES = gql`
  query tenantTopPurchases($supplierId: ID, $tenantId: ID, $fromDate: ISODate, $toDate: ISODate) {
    orderItemAggregationNew(
      tenantId: $tenantId
      supplierId: $supplierId
      fromDate: $fromDate
      toDate: $toDate
      aggregateBy: "product"
      sortBy: "sumTotalNetPricePerUnit"
    ) {
      nodes {
        supplierId
        quantity
        sumPrice
        numberOfOrders
        pricedOrderItemAverage
      }
    }
  }
`;

export const ORDER_AGGREGATION_BY_MONTH_QUERY = gql`
  query orderAggregationByMonth($businessId: ID!, $supplierId: ID, $fromMonth: ISOMonth, $toMonth: ISOMonth) {
    orderAggregation {
      groupByMonth(businessId: $businessId, supplierId: $supplierId, fromMonth: $fromMonth, toMonth: $toMonth) {
        nodes {
          amount
          productCount
          orderCount
          isoMonth
        }
      }
    }
  }
`;
