import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function usePriceLists(supplierId) {
  const { error } = useNotification();
  const { result, loading, onError, refetch } = useQuery(PRICE_LISTS_QUERY, { supplierId }, () => ({
    enabled: !!supplierId.value,
  }));
  const priceLists = useResult(result, []);

  onError((err) => {
    console.error('usePriceLists', err);
    error();
  });

  return {
    priceLists,
    loading,
    refetch,
  };
}

const PRICE_LISTS_QUERY = gql`
  query priceLists($supplierId: ID) {
    priceLists(supplierId: $supplierId) {
      id
      name
    }
  }
`;
