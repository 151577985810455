import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

import { PRICE_CHANGE_IMPLICATIONS_QUERY } from './graphql';

function useFullPriceChangeImplications({
  customerId,
  fromDate,
  toDate,
  useCache,
  filterVolatility,
  filterMoreThenOneInstancePerOrder,
}) {
  const { error } = useNotification();
  const { result, loading, onError, refetch } = useQuery(PRICE_CHANGE_IMPLICATIONS_QUERY, {
    customerId,
    fromDate,
    toDate,
    useCache,
    filterVolatility,
    filterMoreThenOneInstancePerOrder,
  });

  const priceChangeImplications = useResult(result, { nodes: [] });

  onError((err) => {
    console.error('usePriceChangeImplications', err);
    error();
  });

  return {
    priceChangeImplications: computed(() => priceChangeImplications.value.nodes ?? []),
    loading,
    refetch,
  };
}

const GENERAL_PRODUCT_NAME = 'כללי';
export function usePriceChangeImplications({
  customerId,
  fromDate,
  toDate,
  useCache = 'true',
  filterVolatility = 'true',
  filterMoreThenOneInstancePerOrder = 'true',
}) {
  const { priceChangeImplications, loading } = useFullPriceChangeImplications({
    customerId,
    fromDate,
    toDate,
    useCache,
    filterVolatility,
    filterMoreThenOneInstancePerOrder,
  });
  const filteredPriceChangeImplications = computed(() =>
    priceChangeImplications.value.filter((implication) => implication.product.name !== GENERAL_PRODUCT_NAME)
  );
  const summaryData = computed(() => {
    const positiveImplications = filteredPriceChangeImplications.value?.filter(
      (implication) => implication.percentageChange > 0
    );
    const negativeImplications = filteredPriceChangeImplications.value?.filter(
      (implication) => implication.percentageChange < 0
    );
    const numberOfPositiveImplications = positiveImplications.length;
    const numberOfNegativeImplications = negativeImplications.length;
    const negativeImplicationsSum = negativeImplications.reduce(
      (sum, implication) => sum + implication.estimatedYearlyImpact,
      0
    );
    const positiveImplicationsSum = positiveImplications.reduce(
      (sum, implication) => sum + implication.estimatedYearlyImpact,
      0
    );
    const totalImplicationsSum = negativeImplicationsSum + positiveImplicationsSum;
    const totalNumberOfImplications = numberOfNegativeImplications + numberOfPositiveImplications;
    return {
      numberOfNegativeImplications,
      numberOfPositiveImplications,
      negativeImplicationsSum,
      positiveImplicationsSum,
      totalImplicationsSum,
      totalNumberOfImplications,
    };
  });

  return { loading, priceChangeImplications: filteredPriceChangeImplications, summaryData };
}
