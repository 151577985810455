import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

import { ORDER_AGGREGATION_BY_MONTH_QUERY } from './graphql';
import { computed } from 'vue';

const NETWORK_IDLE_STATUS = 7;

export function useOrderAggregationByMonth(businessId, supplierId, fromMonth, toMonth, triggerQuery) {
  const { error } = useNotification();

  const { result, loading, onError, refetch, onResult } = useQuery(
    ORDER_AGGREGATION_BY_MONTH_QUERY,
    {
      businessId,
      supplierId,
      fromMonth,
      toMonth,
    },
    () => ({
      enabled: !!fromMonth.value && !!toMonth.value && !!businessId.value && !!triggerQuery.value,
    })
  );

  onResult(({ networkStatus }) => {
    if (networkStatus === NETWORK_IDLE_STATUS) {
      triggerQuery.value = false;
    }
  });

  const groupByMonth = computed(() => (result.value ? result.value.orderAggregation?.groupByMonth?.nodes ?? [] : []));
  onError((err) => {
    console.error('useOrderAggregationByMonth', err);
    error();
  });

  return {
    groupByMonth,
    loading,
    refetch,
  };
}
