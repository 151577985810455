import { gql } from '@apollo/client/core';

export const AGREEMENT_QUERY = gql`
  query getContract($agreementId: ID!) {
    agreement(agreementId: $agreementId) {
      id
      agreementDate
      agreementType
      fromDate
      toDate
      files {
        key
        signedUrl
      }
      supplier {
        name
      }
    }
  }
`;

export const AGREEMENTS_QUERY = gql`
  query agreements($supplierId: ID, $businessId: ID, $activeAt: Date) {
    agreements(supplierId: $supplierId, businessId: $businessId, activeAt: $activeAt) {
      id
      termIds
      fromDate
      toDate
      agreementDate
      agreementType
    }
  }
`;

export const AGREEMENT_FILE_UPLOAD_QUERY = gql`
  query agreementFileUpload($filenames: [String!]!, $businessId: ID) {
    agreementFileUpload(filenames: $filenames, businessId: $businessId) {
      filePath
      url
      fields
    }
  }
`;

export const CREATE_AGREEMENT_MUTATION = gql`
  mutation createAgreement($agreementCreateInput: AgreementCreateInput) {
    agreementCreate(agreementCreateInput: $agreementCreateInput) {
      id
    }
  }
`;
