import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useNotification } from '@/modules/core';

import { PRODUCT_PURCHASE_SUMMARY } from './graphql';

export function useSupplierOverview(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(PRODUCT_PURCHASE_SUMMARY, variables, () => ({
    enabled: !!variables.value.supplierId && !!variables.value.tenantId,
  }));

  const supplierProductOverviewForDefaultTable = computed(() => {
    const returnValue = {};
    if (result.value) {
      const allKeys = Object.keys(result.value);

      allKeys.forEach((key) => {
        returnValue[key] = result.value[key].nodes;
      });
    }

    return returnValue;
  });

  onError((err) => {
    console.error('useSupplierOverview', err);
    error();
  });

  return {
    supplierProductOverviewForDefaultTable,
    loading,
    refetch,
  };
}
