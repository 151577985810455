import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import {
  SUPPLIERS_PURCHASE_SUMMARY,
  PRODUCTS_SUMMARY_FROM_ALL_SUPPLIERS,
  PRODUCTS_SUMMARY_FROM_ALL_SUPPLIERS_FOR_PROCESS_CART,
} from './graphql';

export function useSuppliersPurchaseManagement(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(SUPPLIERS_PURCHASE_SUMMARY, variables, () => ({
    enabled: !!variables.value.tenantId,
  }));

  const suppliersPurchaseManagementData = computed(() => {
    const returnValue = {};
    if (result.value) {
      const allKeys = Object.keys(result.value);
      allKeys.forEach((key) => {
        if (key === 'productPurchaseSummary') {
          returnValue[key] = result.value[key].nodes;
        } else {
          returnValue[key] = result.value[key].groupBySupplier.nodes;
        }
      });
    }

    return returnValue;
  });

  onError((err) => {
    console.error('useSuppliersPurchaseManagement', err);
    error();
  });

  return {
    suppliersPurchaseManagementData,
    loading,
    refetch,
  };
}

export function useExportAllProducts(variables) {
  const { error } = useNotification();

  const { result, onResult, onError } = useQuery(PRODUCTS_SUMMARY_FROM_ALL_SUPPLIERS, variables, () => ({
    enabled: variables.value.triggerExportProducts,
  }));

  const allProductsData = computed(() => {
    const returnValue = {};
    if (result.value) {
      const allKeys = Object.keys(result.value);

      allKeys.forEach((key) => {
        returnValue[key] = result.value[key].nodes;
      });
    }

    return returnValue;
  });

  onError((err) => {
    console.error('useExportAllProducts', err);
    error();
  });

  return {
    allProductsData,
    onResult,
  };
}

export function useExportProductsForOrderProcessCart(variables) {
  const { error } = useNotification();

  const { result, onResult, onError, loading } = useQuery(
    PRODUCTS_SUMMARY_FROM_ALL_SUPPLIERS_FOR_PROCESS_CART,
    variables,
    () => ({
      enabled: !!variables.value.supplierId,
    })
  );

  const allProductsData = computed(() => result || undefined);

  onError((err) => {
    console.error('useExportAllProducts', err);
    error();
  });

  return {
    loading,
    allProductsData,
    onResult,
  };
}
