import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useSupplierTerms(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(TERMS_QUERY, variables, () => ({
    enabled: !!variables.value.supplierId && !!variables.value.businessId,
  }));
  const terms = useResult(result, []);

  onError((err) => {
    console.error('useSupplierTerms', err);
    error();
  });

  return {
    terms,
    loading,
    refetch,
  };
}

export function useSupplierTermsNew(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(TERMS_NEW_QUERY, variables, () => ({
    enabled: !!variables.value.supplierId && !!variables.value.businessId,
  }));

  const terms = computed(() => (result.value ? result.value.suppliersNew?.nodes ?? [] : []));

  onError((err) => {
    console.error('useSupplierTermsNew', err);
    error();
  });

  return {
    terms,
    loading,
    refetch,
  };
}

export const TERMS_NEW_QUERY = gql`
  query terms($supplierId: ID, $businessId: ID) {
    termsNew(supplierId: $supplierId, businessId: $businessId) {
      nodes {
        id
        active
        fromDate
        toDate
        productId
        type: typeNew
        pricingMethod
        price
        priceListId
        rewardType
        rewardRealizationTime
        rewardValue
      }
    }
  }
`;

export const TERMS_QUERY = gql`
  query terms($supplierId: ID, $businessId: ID) {
    terms(supplierId: $supplierId, businessId: $businessId) {
      id
      active
      fromDate
      toDate
      productId
      type: typeNew
      pricingMethod
      price
      priceListId
      rewardType
      rewardRealizationTime
      rewardValue
    }
  }
`;
