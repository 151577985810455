import { gql } from '@apollo/client/core';

export const PRODUCT_PURCHASE_SUMMARY = gql`
  query productPurchaseSummary(
    $supplierId: ID
    $tenantId: ID
    $fromDate: ISODate
    $toDate: ISODate
    $fromDate1: ISODate
    $toDate1: ISODate
    $fromDate2: ISODate
    $toDate2: ISODate
    $previousPeriodFromDate: ISODate
    $previousPeriodToDate: ISODate
    $getFirstComparisonDate: Boolean!
    $getThreeTimePeriods: Boolean!
  ) {
    productPurchaseSummary: orderItemAggregationNew(
      tenantId: $tenantId
      supplierId: $supplierId
      fromDate: $fromDate
      toDate: $toDate
      monthlyAverage: true
      aggregateBy: "product"
    ) {
      nodes {
        product {
          id
          name
          sku
        }
        maxDateOrderItem {
          orderId
          orderDate
          orderDiscountRate
          netAmountPerUnit
          netPrice
          associatedIntegralItemsPricePerUnit
          price
          discount
          quantity
          items {
            productId
            price
            product {
              name
              sku
            }
            quantity
            type
          }
        }
        minDateOrderItem {
          orderId
          orderDate
          netAmountPerUnit
          netPrice
          associatedIntegralItemsPricePerUnit
          price
          discount
          quantity
          items {
            productId
            price
            product {
              name
              sku
            }
            quantity
            type
          }
        }
        minPriceOrderItem {
          netAmountPerUnit
        }
        maxPriceOrderItem {
          netAmountPerUnit
        }
        quantity
        sumPrice
        numberOfOrders
        pricedOrderItemAverage
        monthlyAverageTotalQuantity
        isRecommended
      }
    }

    previousPeriodPurchaseSummary: orderItemAggregationNew(
      tenantId: $tenantId
      supplierId: $supplierId
      fromDate: $previousPeriodFromDate
      toDate: $previousPeriodToDate
      aggregateBy: "product"
    ) {
      nodes {
        productId
        quantity
        sumPrice
        pricedOrderItemAverage
      }
    }
    secondSummaryQuery: orderItemAggregationNew(
      tenantId: $tenantId
      supplierId: $supplierId
      fromDate: $fromDate1
      toDate: $toDate1
      aggregateBy: "product"
    ) @include(if: $getFirstComparisonDate) {
      nodes {
        product {
          id
          name
          sku
        }
        maxDateOrderItem {
          orderId
          orderDate
          netAmountPerUnit
          netPrice
          associatedIntegralItemsPricePerUnit
          price
          discount
          quantity
          items {
            productId
            price
            product {
              name
              sku
            }
            quantity
            type
          }
        }
        minDateOrderItem {
          orderId
          orderDate
          netAmountPerUnit
          netPrice
          associatedIntegralItemsPricePerUnit
          price
          discount
          quantity
          items {
            productId
            price
            product {
              name
              sku
            }
            quantity
            type
          }
        }
        minPriceOrderItem {
          netAmountPerUnit
        }
        maxPriceOrderItem {
          netAmountPerUnit
        }
        quantity
        sumPrice
        numberOfOrders
        pricedOrderItemAverage
      }
    }

    thirdSummaryQuery: orderItemAggregationNew(
      tenantId: $tenantId
      supplierId: $supplierId
      fromDate: $fromDate2
      toDate: $toDate2
      aggregateBy: "product"
    ) @include(if: $getThreeTimePeriods) {
      nodes {
        product {
          id
          name
          sku
        }
        maxDateOrderItem {
          orderId
          orderDate
          netAmountPerUnit
          netPrice
          associatedIntegralItemsPricePerUnit
          price
          discount
          quantity
          items {
            productId
            price
            product {
              name
              sku
            }
            quantity
            type
          }
        }
        minDateOrderItem {
          orderId
          orderDate
          netAmountPerUnit
          netPrice
          associatedIntegralItemsPricePerUnit
          price
          discount
          quantity
          items {
            productId
            price
            product {
              name
              sku
            }
            quantity
            type
          }
        }
        minPriceOrderItem {
          netAmountPerUnit
        }
        maxPriceOrderItem {
          netAmountPerUnit
        }
        quantity
        sumPrice
        numberOfOrders
        pricedOrderItemAverage
      }
    }
  }
`;

export const SUPPLIERS_PURCHASE_SUMMARY = gql`
  query suppliersPurchaseSummary(
    $tenantId: ID!
    $fromDate: ISODate
    $toDate: ISODate
    $fromDate1: ISODate
    $toDate1: ISODate
    $fromDate2: ISODate
    $toDate2: ISODate
    $getSecondTimePeriod: Boolean!
    $getThreeTimePeriods: Boolean!
  ) {
    productPurchaseSummary: supplierPurchaseSummary(customerId: $tenantId, fromDate: $fromDate, toDate: $toDate) {
      nodes {
        productId
        supplierId
        hasDifference
        amountOfDifference
        annualImpactOfDifference
      }
    }
    supplierPurchaseSummary: orderAggregation {
      groupBySupplier(businessId: $tenantId, fromDate: $fromDate, toDate: $toDate) {
        nodes {
          supplier {
            id
            name
          }
          amount
          productCount
          orderCount
          firstOrder {
            date
          }
          lastOrder {
            date
          }
        }
      }
    }
    secondSummaryQuery: orderAggregation @include(if: $getSecondTimePeriod) {
      groupBySupplier(businessId: $tenantId, fromDate: $fromDate1, toDate: $toDate1) {
        nodes {
          supplier {
            id
            name
          }
          amount
          productCount
          orderCount
          firstOrder {
            date
          }
          lastOrder {
            date
          }
        }
      }
    }
    thirdSummaryQuery: orderAggregation @include(if: $getThreeTimePeriods) {
      groupBySupplier(businessId: $tenantId, fromDate: $fromDate2, toDate: $toDate2) {
        nodes {
          supplier {
            id
            name
          }
          amount
          productCount
          orderCount
          firstOrder {
            date
          }
          lastOrder {
            date
          }
        }
      }
    }
  }
`;

export const PRODUCTS_SUMMARY_FROM_ALL_SUPPLIERS = gql`
  query productSummaryFromAllSuppliers($tenantId: ID!, $fromDate: ISODate, $toDate: ISODate) {
    productPurchaseSummary: orderItemAggregationNew(
      tenantId: $tenantId
      fromDate: $fromDate
      toDate: $toDate
      aggregateBy: "product"
    ) {
      nodes {
        product {
          id
          name
          sku
        }
        supplierId
        maxDateOrderItem {
          orderId
          orderDate
          orderDiscountRate
          netAmountPerUnit
          netPrice
          associatedIntegralItemsPricePerUnit
          price
          discount
          quantity
          items {
            productId
            price
            netAmountPerUnit
            product {
              name
            }
            quantity
            type
          }
        }
        minDateOrderItem {
          orderId
          orderDate
          netAmountPerUnit
          netPrice
          associatedIntegralItemsPricePerUnit
          price
          discount
          quantity
          items {
            productId
            price
            product {
              name
            }
            quantity
            type
          }
        }
        minPriceOrderItem {
          netAmountPerUnit
        }
        maxPriceOrderItem {
          netAmountPerUnit
        }
        quantity
        sumPrice
        numberOfOrders
        pricedOrderItemAverage
        isRecommended
      }
    }

    supplierPurchaseSummary: orderAggregation {
      groupBySupplier(businessId: $tenantId, fromDate: $fromDate, toDate: $toDate) {
        nodes {
          supplier {
            id
            name
          }
          amount
          productCount
          orderCount
          firstOrder {
            date
          }
          lastOrder {
            date
          }
        }
      }
    }
  }
`;

export const PRODUCTS_SUMMARY_FROM_ALL_SUPPLIERS_FOR_PROCESS_CART = gql`
  query productPurchaseSummary($supplierId: ID, $tenantId: ID, $fromDate: ISODate, $toDate: ISODate) {
    productPurchaseSummary: orderItemAggregationNew(
      tenantId: $tenantId
      supplierId: $supplierId
      fromDate: $fromDate
      toDate: $toDate
      monthlyAverage: true
      aggregateBy: "product"
    ) {
      nodes {
        product {
          id
          name
          sku
        }
        supplierId
        maxDateOrderItem {
          orderId
          orderDate
          orderDiscountRate
          netAmountPerUnit
          netPrice
          associatedIntegralItemsPricePerUnit
          price
          discount
          quantity
          items {
            productId
            price
            netAmountPerUnit
            product {
              name
            }
            quantity
            type
          }
        }
        minDateOrderItem {
          orderDate
        }
        quantity
        sumPrice
        numberOfOrders
        pricedOrderItemAverage
        isRecommended
        monthlyAverageNumberOfOrders
      }
    }
  }
`;
