import { useNotification } from '@/modules/core';
import { useQuery } from '@vue/apollo-composable';
import { TENANT_TOP_PURCHASES } from './graphql';
import { computed } from 'vue';

export function useTenantTopPurchases(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(TENANT_TOP_PURCHASES, variables, () => ({
    enabled: !!variables.value.tenantId,
  }));

  const tenantTopPurchases = computed(() => (result.value ? result.value.orderItemAggregationNew.nodes ?? [] : []));

  onError((err) => {
    console.error('useTenantTopPurchases', err);
    error();
  });

  return {
    tenantTopPurchases,
    loading,
    refetch,
  };
}
