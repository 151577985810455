import { ref, computed } from 'vue';

export function useFetchAll({ limit, dataSelector, hook, variables, options = () => ({}) }) {
  const page = ref(0);
  const after = computed(() => limit * page.value);
  const allResults = ref([]);
  const fetchedResultsCount = ref(0);
  const isDone = ref(false);
  const initialFetchDone = ref(false);
  const fetchAllIsLoading = ref(false);
  const total = ref(0);

  const { onResult, refetch, loading: hookIsLoading } = hook({ ...variables, first: limit, after }, options);

  const refetchPaging = () => {
    allResults.value = [];
    fetchedResultsCount.value = 0;
    isDone.value = false;
    initialFetchDone.value = false;
    fetchAllIsLoading.value = false;
    page.value = 0;
    refetch({ ...variables, first: limit, after: 0 });
  };

  const fetchAllData = async (totalCount) => {
    const totalRequests = Math.ceil(totalCount / limit) - 1;
    const promises = [];

    for (let i = 1; i <= totalRequests; i++) {
      promises.push(refetch({ ...variables, first: limit, after: i * limit }));
    }

    try {
      const results = await Promise.all(promises);

      for (const { data } of results) {
        if (data && data[dataSelector] && data[dataSelector].nodes) {
          const fetchedResults = data[dataSelector].nodes;
          allResults.value.push(...fetchedResults);
        } else {
          console.error(`Error fetching data for ${dataSelector}:`, data);
        }
      }

      fetchedResultsCount.value = allResults.value.length;
      isDone.value = true;
    } catch (e) {
      console.error('Error fetching data:', e);
    } finally {
      fetchAllIsLoading.value = false;
    }
  };

  onResult(async ({ data }) => {
    if (!initialFetchDone.value) {
      initialFetchDone.value = true;

      const results = data[dataSelector].nodes;
      const totalCount = data[dataSelector].totalCount;
      total.value = totalCount;
      allResults.value.push(...results);
      fetchedResultsCount.value += results.length;

      if (totalCount > limit && fetchedResultsCount.value < totalCount) {
        fetchAllIsLoading.value = true;

        await fetchAllData(totalCount);
      } else {
        isDone.value = true;
      }
    }
  });

  const loading = computed(() => fetchAllIsLoading.value || hookIsLoading.value);

  return {
    allResults,
    loading,
    refetch: refetchPaging,
    isDone,
    totalCount: total,
  };
}
