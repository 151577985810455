import { gql } from '@apollo/client/core';

export const PRICE_CHANGE_IMPLICATIONS_QUERY = gql`
  query priceChangeImplications(
    $customerId: ID
    $fromDate: ISODate
    $toDate: ISODate
    $useCache: String
    $filterVolatility: String
    $filterMoreThenOneInstancePerOrder: String
  ) {
    priceChangeImplications(
      customerId: $customerId
      fromDate: $fromDate
      toDate: $toDate
      useCache: $useCache
      filterVolatility: $filterVolatility
      filterMoreThenOneInstancePerOrder: $filterMoreThenOneInstancePerOrder
    ) {
      nodes {
        productId
        product {
          name
          sku
          business {
            name
          }
        }
        estimatedYearlyQuantity
        estimatedYearlyImpact
        percentageChange
        latestPriceLevel {
          startPoint {
            pricedAt
          }
          pricedAtPoint {
            pricedAt
            netPrice
          }
        }
        comparedToPriceLevel {
          pricedAtPoint {
            pricedAt
            netPrice
          }
        }
      }
    }
  }
`;
